import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { matchPath } from 'react-router-dom';
import moment from 'moment';

import { PROGRESS_STATUS, ACCESS_TYPES, UNIT_TYPE } from 'constants/courses';

import { GlobalState } from 'types';
import { Unit, UnitSchedule, UnitProgress } from 'types/learner';

import navRoutes from 'navigation/Routes';

import { profileActions } from 'redux/actions/common';
import {
  courseActions,
  courseProgressActions,
  courseScheduleActions,
  journalActions,
  moduleActions,
} from 'redux/actions/learner';
import {
  getSlugs,
  getModules,
  getUnits,
  getUnitProgress,
  getUnitSchedules,
  getModulesProgress,
  getModuleSchedules,
} from 'redux/selectors/course';
import { hooks, capitalize, courseUtils } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  Box,
  Card,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  chakra,
  useTheme,
} from '@workshop/ui';

import { InformationCard } from 'components/Common';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { ModuleListItem } from 'components/ModulesList';
import { ModalVideo } from 'components/ModalVideo';
import { CourseMenu } from 'components/SideMenu';

import {
  UnitDetailsCard,
  getModulesForUnitDetailsCard,
} from './UnitDetailsCard';
import {
  DownloadsModalBody,
  ChecklistModalBody,
  NotesModalBody,
} from './CourseModals';

import { CourseModel } from 'models/learner';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const BrowseCourse: React.FC<Props> = ({
  courseSlug,
  course,
  courseProgress,
  courseSchedule,
  location,
  modules,
  moduleProgress,
  moduleSchedule,
  units,
  unitProgress,
  unitSchedule,
  journalEntries,
  cohort,
  history,
}) => {
  const isPublicView = matchPath(location.pathname, {
    path: navRoutes.global.publicCourse.path(),
    exact: true,
    strict: false,
  });

  const [modalVideo, setModalVideo] = useState<{
    summary?: string;
    video?: string;
    orientation?: 'portrait' | 'landscape';
    mediaType?: 'text' | 'image' | 'audio' | 'video' | 'embed';
  } | null>(null);

  const [modalState, setModalState] = useState<{
    visible: boolean;
    modal: 'notes' | 'requirements' | 'resources' | null;
    unit: number | null;
  }>({ visible: false, modal: null, unit: null });

  const dispatch = useDispatch();

  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: [
          () => courseActions.retrieve(courseSlug, location.pathname, false),
        ],
      },
      journalLoading: {
        actions: !isPublicView ? [journalActions.retrieveJournalEntries] : [],
      },
    },
    [courseSlug]
  );

  const { dataLoading } = hooks.useLoadingDataState(
    {
      dataLoading: {
        startLoading: !Boolean(courseLoading),
        actions: !isPublicView
          ? [
              () => courseProgressActions.retrieve(courseSlug),
              () => courseScheduleActions.retrieve(courseSlug),
            ]
          : [],
      },
    },
    [courseSlug, courseLoading]
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const courseModel = new CourseModel({
    course,
    courseSchedule: isPublicView ? null : courseSchedule,
    courseProgress: isPublicView ? null : courseProgress,
    modules,
    moduleSchedule: isPublicView ? null : moduleSchedule,
    moduleProgress: isPublicView ? null : moduleProgress,
    units,
    unitSchedule: isPublicView ? null : unitSchedule,
    unitProgress: isPublicView ? null : unitProgress,
    journalEntries: isPublicView ? null : journalEntries,
    cohort: cohort,
  });

  const detailedUnits = courseModel.getDetailedUnits();
  const introUnit = courseModel.getIntroUnit();
  const outroUnit = courseModel.getOutroUnit();

  const introVideo = courseUtils.getMediaFromUnit(introUnit);
  const outroVideo = courseUtils.getMediaFromUnit(outroUnit);

  const unitForModal = modalState.unit
    ? detailedUnits.find((u) => u.id === modalState.unit)
    : null;

  if (!dataLoading && !courseLoading) {
    if (
      (isPublicView && !course.isPublic) ||
      (!isPublicView && !courseProgress)
    ) {
      history.push(navRoutes.common.home.path());
      return null;
    }
  }

  return (
    <>
      <ScreenWrapper>
        {!isPublicView && (
          <CourseMenu
            courseSlug={courseSlug}
            isMini={course?.courseType === 'mini'}
          />
        )}
        {cohort?.accessType === ACCESS_TYPES.scheduled && !isPublicView ? (
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="browse_course_scheduled_cohort" mb={4} />
          </Box>
        ) : cohort?.accessType === ACCESS_TYPES.open && !isPublicView ? (
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="browse_course_open_cohort" mb={4} />
          </Box>
        ) : null}
        <Flex flex={1} flexDirection="column" position="relative">
          {introUnit && (introVideo || introUnit.detailedSummary) && (
            <Card mb={2} padding={0}>
              <ModuleListItem
                isLoading={dataLoading}
                onOpen={() =>
                  setModalVideo({
                    video: introVideo || undefined,
                    summary: introUnit.detailedSummary,
                    mediaType: introUnit.unitClipType,
                    orientation: introUnit.orientation,
                  })
                }
                openBtnLabel="Open Course Intro"
                showImage={false}
                showScheduleBtn={false}
                title={introUnit.title}
              />
            </Card>
          )}
          {detailedUnits.map((unit, idx) => {
            const unitModules = getModulesForUnitDetailsCard({
              dataLoading,
              courseSlug,
              setModalVideo,
              unit,
              isMobile,
              context: isPublicView ? 'public' : 'learner',
            });

            return unitModules.length ? (
              <Box
                key={unit.slug}
                mb={idx + 1 === detailedUnits.length ? 0 : 2}
              >
                <UnitDetailsCard
                  isLoading={dataLoading}
                  tag={unit.tag}
                  description={unit.description}
                  startDate={unit.startDate}
                  title={unit.title}
                  modules={unitModules}
                  onOpenChecklist={
                    unit.unitCheckLists.length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'requirements',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  onOpenDownloads={
                    unit.downloads.length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'resources',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  onOpenNotes={
                    unit.unitNotes.length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'notes',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  percentageComplete={
                    isPublicView ? 0 : unit.percentageComplete
                  }
                  locked={isPublicView ? false : unit.locked}
                  isAssessment={
                    isPublicView
                      ? false
                      : unit.unitType === UNIT_TYPE.assessment
                  }
                  assessmentStartText={unit.startText}
                  onStartAssessment={async () => {
                    if (
                      unit.unitType === UNIT_TYPE.assessment &&
                      unit.progress
                    ) {
                      await dispatch(
                        moduleActions.updateUserProgress(
                          unit.course,
                          unit.progress.moduleProgress[0],
                          {
                            status: PROGRESS_STATUS.inProgress,
                          }
                        )
                      );
                      await dispatch(profileActions.fetchUserProfile());
                      history.push(
                        navRoutes.learner.assessment.path(courseSlug)
                      );
                    }
                  }}
                  isExpired={Boolean(
                    courseProgress?.expiryDate &&
                      moment(courseProgress.expiryDate).diff(
                        moment(),
                        'days'
                      ) <= 0
                  )}
                  alwaysExpanded={Boolean(
                    detailedUnits.length === 1 && (!unit.locked || isPublicView)
                  )}
                />
              </Box>
            ) : null;
          })}
          {outroUnit &&
            (outroVideo || outroUnit.detailedSummary) &&
            (courseProgress?.status === PROGRESS_STATUS.complete ||
              isPublicView) && (
              <Card mt={2} padding={0}>
                <ModuleListItem
                  isLoading={dataLoading}
                  onOpen={() =>
                    setModalVideo({
                      video: outroVideo || undefined,
                      summary: outroUnit.detailedSummary,
                      mediaType: outroUnit.unitClipType,
                      orientation: outroUnit.orientation,
                    })
                  }
                  openBtnLabel="Open Course Outro"
                  showImage={false}
                  showScheduleBtn={false}
                  title={outroUnit.title}
                />
              </Card>
            )}
        </Flex>
      </ScreenWrapper>
      {/* TODO: Move modals to 'screens/learner/BrowseCourse/src/CourseModals' */}
      {/* MODAL FOR NOTES/CHECKLIST/DOWNLOADS */}
      <Modal
        size="lg"
        isOpen={modalState.visible}
        onClose={() =>
          setModalState({ visible: false, modal: null, unit: null })
        }
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" overflow="hidden">
          <ModalHeader padding={4}>
            <Flex alignItems="center">
              <Text fontWeight="bold" pr={8}>
                {`${capitalize(modalState.modal)}`}
                <chakra.span
                  fontWeight="semibold"
                  color="text.muted"
                >{` for ${unitForModal?.title}`}</chakra.span>
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          {unitForModal && (
            <ModalBody padding={0}>
              {modalState.modal === 'resources' ? (
                <DownloadsModalBody
                  downloads={unitForModal.downloads.filter(
                    (d) => !d.teacherOnly
                  )}
                  onClickVideo={(video: string) => {
                    setModalState({ visible: false, modal: null, unit: null });
                    setModalVideo({ video });
                  }}
                />
              ) : modalState.modal === 'requirements' ? (
                <ChecklistModalBody checklists={unitForModal.unitCheckLists} />
              ) : modalState.modal === 'notes' ? (
                <NotesModalBody notes={unitForModal.unitNotes} />
              ) : null}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      {/** MODAL FOR COURSE/UNIT INTROS/OUTROS */}
      <ModalVideo
        isOpen={Boolean(modalVideo)}
        onClose={() => setModalVideo(null)}
        autoplay
        {...modalVideo}
      />
    </>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    courses: {
      courses: courseState,
      units: unitState,
      modules: moduleState,
      cohorts,
    },
    courseSchedule: {
      courses: courseScheduleState,
      units: unitScheduleState,
      modules: moduleScheduleState,
    },
    courseProgress: {
      courses: courseProgressState,
      units: unitProgressState,
      modules: moduleProgressState,
    },
    journal: { journalEntries },
  } = state.learner;

  // Course Data
  const course = courseState.detail[courseSlug];
  const units = course ? getUnits(unitState, course.units) : null;

  type U = Unit;
  type M = 'modules';
  const moduleSlugs = getSlugs<Pick<U, M>, M>(units, 'modules');

  const modules = moduleSlugs ? getModules(moduleState, moduleSlugs) : null;

  // Course Schedule Data
  const courseSchedule = courseScheduleState[courseSlug];
  const unitSchedule = courseSchedule
    ? getUnitSchedules(unitScheduleState, courseSchedule.unitSchedules)
    : null;

  type US = UnitSchedule;
  type MS = 'moduleSchedules';
  const moduleScheduleSlugs = getSlugs<Pick<US, MS>, MS>(
    unitSchedule,
    'moduleSchedules'
  );

  const moduleSchedule = moduleScheduleSlugs
    ? getModuleSchedules(moduleScheduleState, moduleScheduleSlugs)
    : null;

  // Course Progress Data
  const courseProgress = courseProgressState[courseSlug];
  const unitProgress = courseProgress
    ? getUnitProgress(unitProgressState, courseProgress.unitProgress)
    : null;

  type UP = UnitProgress;
  type MP = 'moduleProgress';
  const moduleProgressSlugs = getSlugs<Pick<UP, MP>, MP>(
    unitProgress,
    'moduleProgress'
  );

  const moduleProgress = moduleProgressSlugs
    ? getModulesProgress(moduleProgressState, moduleProgressSlugs)
    : null;

  // Cohort Data
  const cohort = cohorts[courseSlug];

  return {
    courseSlug,
    course,
    courseProgress,
    courseSchedule,
    units,
    unitProgress,
    unitSchedule,
    modules,
    moduleProgress,
    moduleSchedule,
    journalEntries,
    cohort,
  };
};

const connector = connect(mapStateToProps);

export default connector(BrowseCourse);
