import React, { useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import navRoutes from 'navigation/Routes';

import { Module } from 'types/learner';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
  Card,
  Divider,
  Flex,
  Box,
  Text,
  Collapse,
  MdIcon,
} from '@workshop/ui';

import { DetailedUnit } from 'models/learner';

import { courseUtils } from 'utils';

import { SESSION_TYPE } from 'constants/courses';

import { ConfirmModal } from 'components/Common';
import { ModulesList, ModuleListItem } from 'components/ModulesList';
import { ModuleListItemProps } from 'components/ModulesList/ModuleListItem';

interface UnitDetailsCardProps {
  tag: string;
  description: string;
  startDate: string;
  isLoading: boolean;
  modules: ModuleListItemProps[];
  title: string;
  percentageComplete: number;
  locked?: boolean;
  isAssessment: boolean;
  assessmentStartText?: string;
  isPreview?: boolean;
  assessmentActive?: boolean;
  alwaysExpanded?: boolean;
  liveRoomsAvailable?: boolean;
  isExpired?: boolean;
  onOpenChecklist?: () => void;
  onOpenDownloads?: () => void;
  onOpenTeacherDownloads?: () => void;
  onOpenNotes?: () => void;
  onStartAssessment?: () => Promise<void>;
}

const getModuleTitle = (
  module: Module,
  hasIntro: boolean,
  isMobile: boolean
) => {
  if (module.moduleType === 'intro') return 'Unit Intro';
  if (module.moduleType === 'outro') return 'Unit Outro';
  if (isMobile) return module.title;

  const sessionIdx = hasIntro ? module.index - 1 : module.index;
  return `Session ${sessionIdx}: ${module.title}`;
};

export const getModulesForUnitDetailsCard = ({
  dataLoading,
  courseSlug,
  courseId,
  setModalVideo,
  unit,
  isMobile,
  context,
}: {
  dataLoading: boolean;
  courseSlug: string;
  courseId?: string;
  setModalVideo: (args: {
    summary?: string;
    video?: string;
    orientation?: 'portrait' | 'landscape';
    mediaType?: 'text' | 'image' | 'audio' | 'video' | 'embed';
  }) => void;
  unit: DetailedUnit;
  isMobile: boolean;
  context: 'learner' | 'preview' | 'assessment' | 'public';
}) =>
  unit.modules
    .filter((m) => {
      if (m.moduleType === 'normal') return true;
      return m?.steps[0]?.videoClips[0];
    })
    .map((m) => {
      const hasIntro = unit.modules[0]?.moduleType === 'intro';
      const module = {
        ...m,
        imageUrl: m.imageThumbnail,
        isLoading: dataLoading,
        showScheduleBtn: false,
        title: getModuleTitle(m, hasIntro, isMobile),
      };
      const introOutroClip = m.steps[0].videoClips[0];

      return m.moduleType === 'normal'
        ? {
            ...module,
            linkTo:
              context === 'learner'
                ? navRoutes.learner.module.path(courseSlug, m.id)
                : context === 'assessment'
                ? navRoutes.learner.assessmentModule.path(courseSlug, m.id)
                : context === 'preview'
                ? navRoutes.cms.previewSession.path(courseId, m.id)
                : navRoutes.global.publicSession.path(courseSlug, m.id),
            moduleFormat: m.moduleFormat,
            showImage: true,
            showScheduleBtn: true,
            title: getModuleTitle(m, hasIntro, isMobile),
            courseSlug,
          }
        : {
            ...module,
            moduleFormat: null,
            showImage: false,
            onOpen: () =>
              setModalVideo({
                video:
                  courseUtils.getMediaFromClip(introOutroClip) || undefined,
                summary: introOutroClip.summary,
                mediaType: introOutroClip.clipType,
                orientation: introOutroClip.orientation,
              }),
          };
    });

export const UnitDetailsCard: React.FC<UnitDetailsCardProps> = ({
  tag,
  description,
  startDate,
  isLoading,
  modules,
  title,
  percentageComplete,
  locked,
  isAssessment = false,
  assessmentStartText,
  isPreview = false,
  assessmentActive = false,
  alwaysExpanded = false,
  liveRoomsAvailable = false,
  isExpired = false,
  onOpenChecklist,
  onOpenDownloads,
  onOpenTeacherDownloads,
  onOpenNotes,
  onStartAssessment,
}) => {
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const hasUploadMissing = !!modules.find((m) => m.uploadMissing);
  const introModule = modules.find((m) => m.moduleType === SESSION_TYPE.intro);
  return (
    <Card padding={0} overflow="inherit">
      <Accordion
        allowToggle={!alwaysExpanded}
        allowMultiple={false}
        width="100%"
        reduceMotion
        {...(alwaysExpanded ? { defaultIndex: 0 } : {})}
      >
        <AccordionItem
          borderTopWidth={0}
          borderBottomWidth="0px!important"
          isDisabled={locked}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                paddingY={3}
                borderTopRadius={{ base: 0, md: 'lg' }}
                borderBottomRadius={{ base: 0, md: isExpanded ? 0 : 'lg' }}
                _hover={{
                  background: !alwaysExpanded
                    ? 'background.tint1'
                    : 'transparent',
                }}
                _focus={{ boxShadow: 'none' }}
                {...(alwaysExpanded ? { cursor: 'auto' } : {})}
              >
                <Flex flex={1} textAlign="left" mr="defaultMargin">
                  <Flex alignItems="center">
                    <Box
                      position="relative"
                      backgroundColor="background.success"
                      borderRadius="sm"
                      minWidth="65px"
                      justifyContent="center"
                      px={2}
                      py={1}
                      mr="defaultMargin"
                    >
                      <Text
                        fontSize="xs"
                        color="text.success"
                        fontWeight="semibold"
                        textAlign="center"
                      >
                        {tag}
                      </Text>
                      <Box
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        justifyContent="center"
                        px={2}
                        py={1}
                        borderRadius="sm"
                        clipPath={`inset(0 0 0 ${percentageComplete * 100}%)`}
                        backgroundColor={
                          isAssessment
                            ? 'background.warning'
                            : 'background.tint3'
                        }
                      >
                        <Text
                          fontSize="xs"
                          color={isAssessment ? 'text.warning' : 'text.muted'}
                          fontWeight="semibold"
                          textAlign="center"
                        >
                          {tag}
                        </Text>
                      </Box>
                    </Box>
                    <Text fontWeight="semibold">{title}</Text>
                  </Flex>
                </Flex>
                <Flex alignItems="center">
                  {startDate !== '' && percentageComplete === 0 && (
                    <Text
                      fontSize="sm"
                      color="text.muted"
                    >{`Starts ${startDate}`}</Text>
                  )}
                  {alwaysExpanded ? null : !locked ? (
                    <>
                      {hasUploadMissing && (
                        <MdIcon
                          name="PostAdd"
                          boxSize="icon"
                          color="icon.warning"
                          ml={2}
                        />
                      )}
                      <Text
                        ml="defaultMargin"
                        color="text.muted"
                        display={{ base: 'none', md: 'inline' }}
                      >
                        {isExpanded ? 'Dismiss' : 'Expand'}
                      </Text>
                      <AccordionIcon ml={1} color="text.muted" />
                    </>
                  ) : (
                    <MdIcon
                      name="Lock"
                      boxSize="icon"
                      color="icon.muted"
                      ml={2}
                    />
                  )}
                </Flex>
              </AccordionButton>
              <Collapse
                in={isExpanded || alwaysExpanded}
                style={{ overflow: 'inherit' }}
              >
                <AccordionPanel padding={0}>
                  <Divider />
                  {!!description && (
                    <>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(description),
                        }}
                        sx={{
                          padding: 4,
                          p: {
                            paddingBottom: 3,
                          },
                          ul: {
                            paddingBottom: 3,
                            paddingLeft: 8,
                          },
                        }}
                      />
                      <Divider />
                    </>
                  )}
                  {onOpenTeacherDownloads && (
                    <>
                      <Box flex={1} p={2} background="background.primary">
                        <Button
                          variant="outline"
                          fontSize="sm"
                          icon="School"
                          onClick={onOpenTeacherDownloads}
                          w="100%"
                          _hover={{ backgroundColor: 'background.tint3' }}
                        >
                          Teacher Resources
                        </Button>
                      </Box>
                      <Divider />
                    </>
                  )}
                  {(onOpenChecklist ||
                    (onOpenNotes && !isAssessment) ||
                    onOpenDownloads) && (
                    <>
                      <Flex
                        flexDirection={{ base: 'column', sm: 'row' }}
                        p={2}
                        pl={isAssessment ? 0 : 2}
                        alignItems={{ base: 'normal', sm: 'center' }}
                        flexWrap="wrap"
                      >
                        {onOpenNotes && !isAssessment && (
                          <Button
                            secondary
                            fontSize="sm"
                            icon="InfoOutline"
                            onClick={onOpenNotes}
                            flex={{ base: 'none', sm: 1 }}
                          >
                            Notes
                          </Button>
                        )}
                        {onOpenChecklist && (
                          <Button
                            secondary
                            fontSize="sm"
                            icon="Checklist"
                            onClick={onOpenChecklist}
                            ml={{ base: 0, sm: 2 }}
                            mt={{ base: 2, sm: 0 }}
                            flex={{ base: 'none', sm: 1 }}
                          >
                            Requirements
                          </Button>
                        )}
                        {onOpenDownloads && (
                          <Button
                            secondary
                            fontSize="sm"
                            icon="GetApp"
                            onClick={onOpenDownloads}
                            ml={{ base: 0, sm: 2 }}
                            mt={{ base: 2, sm: 0 }}
                            flex={{ base: 'none', sm: 1 }}
                          >
                            Resources
                          </Button>
                        )}
                      </Flex>
                      <Divider />
                    </>
                  )}
                  {isAssessment &&
                  !assessmentActive &&
                  percentageComplete !== 1 ? (
                    isExpired ? null : (
                      <>
                        {introModule && (
                          <>
                            <ModuleListItem {...introModule} />
                            <Divider />
                          </>
                        )}
                        {onStartAssessment && (
                          <>
                            <Box p={2}>
                              <Button
                                fontSize="sm"
                                variant="outline"
                                icon="Assignment"
                                onClick={() => setShowAssessmentModal(true)}
                                width="100%"
                              >
                                {isPreview
                                  ? 'Preview Assessment'
                                  : 'Start Assessment'}
                              </Button>
                            </Box>
                            <ConfirmModal
                              infoTag={
                                isPreview
                                  ? "This is shown to students before they start their assessment. Don't worry, your access won't be locked."
                                  : ''
                              }
                              body={
                                assessmentStartText ||
                                "Once you start your assessment, your access to everything else on the platform will be locked until the assessment is complete.\n\nMake sure you're ready to go before you start!"
                              }
                              helpText={
                                assessmentStartText
                                  ? 'Note: Once you start, your access to everything else on the platform will be locked until the assessment is complete.'
                                  : ''
                              }
                              btnColor={isPreview ? 'blue' : 'orange'}
                              btnLabel={
                                isPreview
                                  ? 'Preview Assessment'
                                  : 'Yes, Start Assessment'
                              }
                              title="Are You Sure?"
                              isOpen={showAssessmentModal}
                              isLoading={isLoading}
                              onClose={() => setShowAssessmentModal(false)}
                              onClick={onStartAssessment}
                            />
                          </>
                        )}
                      </>
                    )
                  ) : (
                    <ModulesList
                      isLoading={isLoading}
                      moduleItems={modules}
                      isAssessment={isAssessment}
                      liveRoomsAvailable={liveRoomsAvailable}
                      isExpired={isExpired}
                    />
                  )}
                </AccordionPanel>
              </Collapse>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
