import React, { useEffect, useState, useRef } from 'react';
import { useSpring } from 'react-spring';
import sanitizeHtml from 'sanitize-html';

import {
  Box,
  Button,
  Flex,
  MdIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Spinner,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@workshop/ui';

import { IVideoClip } from 'types/cms';

import {
  SessionStepper,
  SessionStepperStep,
} from 'components/SessionPlayer/SessionStepper';
import { CheckList, CheckListProps } from 'components/SessionPlayer/CheckList';
import { Orientation } from 'components/VideoClipsPlayer/types';
import { AddItem, IAddItem } from 'components/ListItem';
import { RenderHtml } from 'components/Common';

import { tourIds } from 'screens/cms/SessionEdit';

export type SessionStepType = 'intro' | 'outro' | 'normal';
export interface SessionPlayerStep {
  notes?: string;
  stepType?: SessionStepType;
  subSteps: SessionStepperStep[];
  title: string;
  id: number | string;
  unlocked?: boolean;
}

interface SessionPlayerProps {
  loading?: boolean;
  onCompleteSession: () => Promise<any>;
  onSetOrienation: (o: Orientation) => void;
  onUnlockStep: (step: number) => Promise<any>;
  requirements?: CheckListProps;
  showRequirements?: boolean;
  steps: SessionPlayerStep[];
  pathname?: string;
  navigationStep: string | null;
  previewModeEnabled?: boolean;
  isEditable?: boolean;
  sessionIsComplete?: boolean;
  isAssessment?: boolean;
  embed?: boolean;
  onSaveClip?: (clipId: string, data: Partial<IVideoClip>) => Promise<any>;
  handleVideoUpload?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => Promise<void>;
  handleAddClip?: (id: string) => Promise<void>;
  handleAddStep?: (data: IAddItem) => Promise<number | null>;
  navigateToStep: (idx: number, endOfStep?: boolean) => void;
}

interface NavigationStep extends Omit<SessionPlayerStep, 'title'> {
  title: string;
}

interface SessionsStepNavigationProps {
  currentStepIdx?: number;
  header?: React.ReactNode;
  loading?: boolean;
  onOpenNotes: (idx: number) => void;
  maxUnlockedIdx: number;
  setCurrentStepIdx: (idx: number) => void;
  steps: NavigationStep[];
  previewModeEnabled?: boolean;
  isEditable?: boolean;
  handleAddStep?: (data: IAddItem) => Promise<number | null>;
  showRequirements?: boolean;
  openRequirements?: () => void;
  disableNavigation?: boolean;
  size?: 'sm' | 'md';
}

interface StepNavItemProps {
  title: NavigationStep['title'];
  notes: SessionPlayerStep['notes'];
  idx: number;
  currentStepIdx?: number;
  setCurrentStepIdx: (idx: number) => void;
  maxUnlockedIdx: number;
  onOpenNotes: (idx: number) => void;
  previewModeEnabled?: boolean;
  isDisabled?: boolean;
  stepType?: SessionStepType;
  hasIntro?: boolean;
  loading?: boolean;
  size?: 'sm' | 'md';
}

const StepNavItem: React.FC<StepNavItemProps> = ({
  title,
  notes,
  idx,
  currentStepIdx,
  setCurrentStepIdx,
  maxUnlockedIdx,
  onOpenNotes,
  previewModeEnabled,
  isDisabled = false,
  stepType,
  hasIntro = true,
  loading = false,
  size = 'md',
}) => {
  const isUnlocked = idx <= maxUnlockedIdx;
  const isCompleteStep = currentStepIdx ? idx < currentStepIdx : false;
  const isCurrentStep = idx === currentStepIdx;

  const itemRef = useRef<HTMLDivElement>(null);

  const [moved, setMoved] = useState(false);

  const [isTouchDevice] = useMediaQuery('(hover: none)');

  useEffect(() => {
    if (isCurrentStep) {
      itemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [isCurrentStep]);

  const cursor = isUnlocked ? 'pointer' : 'not-allowed';
  const onClick = () => (isUnlocked ? setCurrentStepIdx(idx) : null);

  let iconName =
    isUnlocked || previewModeEnabled
      ? stepType === 'intro'
        ? 'WavingHand'
        : stepType === 'outro'
        ? 'DoneOutline'
        : ''
      : 'Lock';
  let iconColor = isUnlocked ? 'text.success' : 'icon.muted';
  let iconBackground = isUnlocked
    ? 'background.success'
    : stepType === 'normal'
    ? 'background.tint2'
    : 'transparent';
  let stepBackground = isCurrentStep ? 'background.primary' : 'transparent';

  let textColor = isCurrentStep
    ? 'white'
    : isUnlocked
    ? 'text.default'
    : 'text.muted';
  let hoverBackground = isUnlocked ? 'background.tint1' : stepBackground;

  if (previewModeEnabled) {
    iconColor = 'icon.muted';
    iconBackground =
      stepType === 'normal'
        ? isCompleteStep
          ? 'background.primary'
          : 'background.tint2'
        : 'transparent';
  }

  if (isCurrentStep) {
    iconColor = 'background.primary';
    iconBackground = 'icon.primary';
    textColor = 'text.primary';
    stepBackground = 'background.primary';
    hoverBackground = 'background.primaryDark';
  }

  return (
    <Box
      ref={itemRef}
      paddingLeft={{
        base: 'defaultMargin',
        md: idx === 0 ? 0 : 'defaultMargin',
        lg: 0,
      }}
      scrollMarginTop={12}
    >
      <Tooltip
        display={{ base: 'none', lg: 'block' }}
        label={title}
        placement="right"
      >
        <Box
          key={idx}
          display="flex"
          alignItems="center"
          backgroundColor={stepBackground}
          padding={1}
          minWidth={{ base: 10, lg: 12 }}
          maxWidth={{ base: '250px', lg: 12 }}
          borderRadius="full"
          style={{ flexFlow: 'row wrap' }}
          {...(!isDisabled
            ? {
                ...(isTouchDevice
                  ? {
                      onTouchStart: () => setMoved(false),
                      onTouchMove: () => setMoved(true),
                      onTouchEnd: () => {
                        if (!moved) {
                          onClick();
                        }
                        setMoved(false);
                      },
                    }
                  : {}),
                _hover: {
                  backgroundColor: hoverBackground,
                },
                onClick,
                cursor,
              }
            : {
                cursor: 'not-allowed',
              })}
        >
          <Flex
            alignItems="center"
            backgroundColor={iconBackground}
            borderRadius="full"
            justifyContent="center"
            boxSize={{ base: size === 'sm' ? 5 : 8, lg: 10 }}
          >
            {loading ? (
              <Spinner size="sm" color="icon.muted" />
            ) : iconName ? (
              <MdIcon
                fontSize={{ base: size === 'sm' ? 'xs' : size, md: size }}
                name={iconName}
                color={iconColor}
              />
            ) : (
              <Flex>
                <Text fontSize={size} fontWeight="bold" color={iconColor}>
                  {hasIntro ? idx : idx - 1}
                </Text>
              </Flex>
            )}
          </Flex>
          {title ? (
            <Flex
              px={{ base: size === 'sm' ? 1.5 : 3, md: size === 'sm' ? 2 : 3 }}
              flex={1}
              display={{ base: 'flex', lg: 'none' }}
            >
              <Text
                noOfLines={1}
                fontSize={{ base: 'xs', md: 'sm' }}
                color={textColor}
              >
                {title}
              </Text>
            </Flex>
          ) : null}

          {/* {isUnlocked && notes && (
          <Button
            secondary
            height={6}
            minW={6}
            padding={0}
            onClick={(e) => {
              e.stopPropagation();
              onOpenNotes(idx);
            }}
            icon="InfoOutline"
          />
        )} */}
        </Box>
      </Tooltip>
    </Box>
  );
};

const SessionStepNavigation: React.FC<SessionsStepNavigationProps> = ({
  currentStepIdx,
  header,
  loading = false,
  maxUnlockedIdx,
  onOpenNotes,
  setCurrentStepIdx,
  steps,
  previewModeEnabled = false,
  isEditable = false,
  handleAddStep = async () => null,
  showRequirements,
  openRequirements = () => null,
  disableNavigation = false,
  size = 'md',
}) => {
  const hasIntro = !!steps.find((s) => s.stepType === 'intro');
  return (
    <Stack
      direction={{ base: 'row', lg: 'column' }}
      height="100%"
      shouldWrapChildren
      overflow="scroll"
      maxWidth={{ base: '100vw', md: '85vw' }}
      pr={{ base: 'defaultMargin', md: 0 }}
      spacing={{ base: 0, lg: 'defaultMargin' }}
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
    >
      {loading ? (
        <>
          <StepNavItem
            title=""
            idx={0}
            notes=""
            setCurrentStepIdx={() => null}
            maxUnlockedIdx={0}
            onOpenNotes={() => null}
            previewModeEnabled
            stepType="normal"
            loading
            size={size}
          />
        </>
      ) : (
        steps.map(({ title, notes }, idx) => (
          <StepNavItem
            key={`stepnavitem-${currentStepIdx}-${idx}`}
            title={title}
            notes={notes}
            idx={idx}
            currentStepIdx={currentStepIdx}
            stepType={steps[idx].stepType}
            setCurrentStepIdx={(idx) => {
              setCurrentStepIdx(idx);
            }}
            maxUnlockedIdx={maxUnlockedIdx}
            onOpenNotes={onOpenNotes}
            previewModeEnabled={previewModeEnabled}
            isDisabled={disableNavigation}
            hasIntro={hasIntro}
            size={size}
          />
        ))
      )}
      {/* {isEditable && (
        <Flex>
          <AddItem
            label="New Step"
            onSave={handleAddStep}
            flexDir="column"
            icon="Queue"
            variant="outline"
          />
        </Flex>
      )} */}
    </Stack>
  );
};

const SessionPlayer: React.FC<SessionPlayerProps> = ({
  pathname,
  loading = false,
  navigationStep,
  requirements,
  showRequirements,
  steps,
  previewModeEnabled = false,
  isEditable = false,
  sessionIsComplete = false,
  isAssessment = false,
  embed = false,
  onCompleteSession,
  onSetOrienation,
  onUnlockStep,
  onSaveClip,
  handleVideoUpload,
  handleAddClip,
  handleAddStep,
  navigateToStep,
}) => {
  const [completeSessionLoading, setCompleteSessionLoading] = useState(false);
  const [currentStepIdx, setCurrentStepIdx] = useState<number | null>(null);
  const [maxUnlockedIdx, setMaxUnlockedIdx] = useState(0);
  const [displayRequirement, setDisplayRequirements] = useState(false);
  const [notesStepIdx, setNotesStepIdx] = useState<number | null>(null);
  const [intitialCheckDone, setInitialCheckDone] = useState(false);
  const [showTopScrollButton, setShowTopScrollButton] = useState(false);
  const [showBottomScrollButton, setShowBottomScrollButton] = useState(false);

  const stepScrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      // @ts-ignore
      stepScrollerRef.current?.scrollHeight >
        // @ts-ignore
        stepScrollerRef.current?.clientHeight &&
      !showBottomScrollButton
    ) {
      setShowBottomScrollButton(true);
    }
  }, [stepScrollerRef.current?.scrollHeight]);

  const [touchBackgroundBlurred, setTouchBackgroundBlurred] = useState(false);
  const [isTouchDevice] = useMediaQuery('(hover: none)');

  const unlockedSteps = JSON.stringify(steps.map((s) => s.unlocked));

  const noPath = !pathname;
  useEffect(() => {
    if (loading || intitialCheckDone || !steps.length || noPath) {
      return;
    }

    const lastUnlockedStep = [...steps].reverse().find((s) => s.unlocked);
    const lastUnlockedStepIdx = lastUnlockedStep
      ? steps.indexOf(lastUnlockedStep)
      : 0;

    const currentNavigationStep = parseInt(navigationStep || '0');

    if (previewModeEnabled || sessionIsComplete) {
      navigateToStep(currentNavigationStep);
    } else if (currentNavigationStep > lastUnlockedStepIdx || isAssessment) {
      navigateToStep(lastUnlockedStepIdx);
    }

    setInitialCheckDone(true);
  }, [steps.length, loading]);

  useEffect(() => {
    const lastUnlockedStep = [...steps].reverse().find((s) => s.unlocked);
    const lastUnlockedStepIdx = lastUnlockedStep
      ? steps.indexOf(lastUnlockedStep)
      : 0;

    if (lastUnlockedStepIdx > maxUnlockedIdx) {
      setMaxUnlockedIdx(lastUnlockedStepIdx);
    }

    if (noPath || navigationStep) return;

    if (previewModeEnabled || sessionIsComplete) {
      navigateToStep(parseInt(navigationStep || '0'));
    } else {
      navigateToStep(lastUnlockedStepIdx);
    }
  }, [unlockedSteps]);

  useEffect(() => {
    if (navigationStep === null || loading || !intitialCheckDone) return;

    setCurrentStepIdx(parseInt(navigationStep));
  }, [navigationStep, loading, intitialCheckDone]);

  if (loading || !steps.length) {
    return (
      <Flex
        height={{ base: 'auto', lg: '100%' }}
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
        maxWidth={embed ? '100vw' : '120vh'}
        minWidth="70%"
      >
        <Box
          {...(embed
            ? {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: { base: 'center', lg: 'flex-start' },
                zIndex: 1,
                py: 2,
                px: 2,
                pointerEvents: 'none',
              }
            : {})}
          height={{ base: 'auto', lg: embed ? 'auto' : '100%' }}
          mb={{ base: 'defaultMargin', lg: 0 }}
        >
          <Box
            {...(embed
              ? {
                  py: { base: 1, md: 1 },
                  px: { base: 0, md: 1 },
                  bg: 'rgba(255, 255, 255, 0.75)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 'full',
                  transition: 'transform 0.5s',
                  transform: {
                    base: 'translateY(-100px)',
                    lg: 'translateX(-100px)',
                  },
                  overflow: 'hidden',
                  _groupHover: {
                    transform: {
                      base: 'translateY(0px)',
                      lg: 'translateX(0px)',
                    },
                  },
                  pointerEvents: 'auto',
                }
              : {})}
          >
            <Box
              {...(embed
                ? {
                    mx: { base: -2, md: 0 },
                  }
                : {})}
            >
              <SessionStepNavigation
                currentStepIdx={0}
                header={<Spinner size="xs" color="icon.muted" />}
                loading
                maxUnlockedIdx={0}
                onOpenNotes={() => null}
                setCurrentStepIdx={() => null}
                steps={[]}
              />
            </Box>
          </Box>
        </Box>
        <Box flex={1} ml={{ base: 0, lg: embed ? 0 : 4 }}>
          <SessionStepper
            loading
            sessionSubsteps={[]}
            currentSessionStepId={null}
            title="Loading..."
            embed={embed}
          />
        </Box>
      </Flex>
    );
  }

  const currentStep = steps[currentStepIdx || 0];
  const { title: stepTitle, subSteps, notes } = currentStep;

  const normalSteps = steps.filter((s) => s.stepType === 'normal');
  const hasNormalSteps = normalSteps.length > 0;

  const isLastStep =
    currentStepIdx !== null ? currentStepIdx + 1 >= steps.length : false;

  const isLastNormalStep =
    currentStep?.stepType === 'normal' && currentStepIdx !== null
      ? currentStepIdx >= normalSteps.length
      : false;

  const isFirstStep = currentStepIdx !== null ? currentStepIdx - 1 < 0 : false;

  const isFirstNormalStep =
    currentStep?.stepType === 'normal' && currentStepIdx !== null
      ? currentStepIdx - 1 === 0
      : false;

  const updatedSubsteps = subSteps.map((s, idx) => {
    const isLastSubstep = idx + 1 >= subSteps.length;
    const isFirstSubstep = idx - 1 < 0;
    const isLastSessionSubStep = isLastStep && isLastSubstep;
    const isFirstSessionSubStep = isFirstStep && isFirstSubstep;

    const onClickNext = async () => {
      if (s.onClickNext) await s.onClickNext();

      /** Only move on to the next step when clicking next if:
       * - this is not the last step
       * - this is the last substep
       * */
      if (isLastStep || !isLastSubstep) return;

      if (currentStepIdx === maxUnlockedIdx) {
        await onUnlockStep(maxUnlockedIdx + 1);
        setMaxUnlockedIdx(maxUnlockedIdx + 1);
      }
      const nextIdx = currentStepIdx !== null ? currentStepIdx + 1 : 1;
      navigateToStep(nextIdx);
    };

    const onClickPrev = async () => {
      if (s.onClickPrev) await s.onClickPrev();

      /** Only move to the prev step when clicking prev if:
       * - this is not the first step
       * - this is the first substep
       * */
      if (isFirstStep || !isFirstSubstep) return;

      const prevIdx = currentStepIdx !== null ? currentStepIdx - 1 : 0;
      navigateToStep(prevIdx, true);
    };

    return {
      ...s,
      notes,
      onClickNext,
      onClickPrev,
      footer:
        isLastSessionSubStep && !isEditable ? (
          <Button
            flex={1}
            marginX={1}
            // TODO: Allow next button & complete button to unlock on 'required'
            // prompt submit (removing need for "isAssessment" checks)
            isDisabled={isAssessment && !sessionIsComplete}
            onClick={async () => {
              setCompleteSessionLoading(true);
              await onCompleteSession();
              setCompleteSessionLoading(false);
            }}
            key={`completeSession-${s.id}`}
          >
            {completeSessionLoading ? (
              <Spinner size="xs" speed="0.75s" />
            ) : sessionIsComplete ? (
              'Close Session'
            ) : (
              'Complete Session'
            )}
          </Button>
        ) : isEditable && handleAddStep && !isLastSessionSubStep ? (
          <Flex
            width="100%"
            mx={1}
            mb={hasNormalSteps ? 3 : 0}
            key={`addStep-${s.id}`}
            // data-tour={tourIds.sessionFirstStep}
          >
            <AddItem
              button
              variant="dotted"
              label="Add a Step"
              onSave={async (data) => {
                await handleAddStep(data);
                navigateToStep(steps.length - 1);
              }}
              hideCard
              icon="Queue"
            />
          </Flex>
        ) : null,
      showNextBtn: !isLastSessionSubStep && (!isEditable || hasNormalSteps),
      nextLabel:
        isLastSubstep && isLastNormalStep && isEditable
          ? 'Open Outro'
          : isLastSubstep
          ? isEditable
            ? 'Open Next Step'
            : 'Next Step'
          : 'Next',
      showPrevBtn: !isFirstSessionSubStep && !isAssessment,
      prevLabel:
        isFirstSubstep && isFirstNormalStep && isEditable
          ? 'Open Intro'
          : isFirstSubstep
          ? 'Previous Step'
          : 'Previous',
    };
  });

  // TODO: Decide whether the requirements checklist shown in the modal
  // should be interactable or rendered as all checked.
  const requirementItems =
    requirements &&
    requirements.items.map((i) => ({
      ...i,
      isChecked: true,
    }));

  const notesToDisplay =
    notesStepIdx !== null ? steps[notesStepIdx].notes : null;

  return (
    <Flex
      height={{ base: 'auto', lg: '100%' }}
      flexDirection={{ base: 'column', lg: 'row' }}
      maxWidth={embed ? '100vw' : '120vh'}
      minWidth="70%"
      width="100%"
      mx="auto"
    >
      <Box
        {...(embed
          ? {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: { base: 'center', lg: 'flex-start' },
              zIndex: 1,
              py: 2,
              px: 2,
              pointerEvents: 'none',
            }
          : {
              position: 'relative',
              width: { base: 'auto', lg: 12 },
            })}
        mb={{ base: 'defaultMargin', lg: 0 }}
        data-tour={tourIds.sessionStepperSteps}
      >
        {!embed && showTopScrollButton && (
          <Flex
            position="absolute"
            top={0}
            display={{ base: 'none', lg: 'flex' }}
            zIndex={1}
            bg="background.tint3"
          >
            <Box
              display="flex"
              alignItems="center"
              padding={1}
              minWidth={12}
              maxWidth={12}
              borderRadius="full"
              style={{ flexFlow: 'row wrap' }}
              cursor="pointer"
              _hover={{
                bg: 'background.tint1',
              }}
              onClick={() => {
                stepScrollerRef.current?.scrollTo({
                  top: stepScrollerRef.current?.scrollTop - 60,
                  behavior: 'smooth',
                });
              }}
            >
              <Flex
                alignItems="center"
                borderRadius="full"
                justifyContent="center"
                boxSize={10}
              >
                <MdIcon fontSize="md" name="ArrowUpward" color="icon.muted" />
              </Flex>
            </Box>
          </Flex>
        )}
        {!embed && showBottomScrollButton && (
          <Flex
            position="absolute"
            bottom={0}
            display={{ base: 'none', lg: 'flex' }}
          >
            <Box
              display="flex"
              alignItems="center"
              padding={1}
              minWidth={12}
              maxWidth={12}
              borderRadius="full"
              style={{ flexFlow: 'row wrap' }}
              cursor="pointer"
              _hover={{
                bg: 'background.tint1',
              }}
              onClick={() => {
                stepScrollerRef.current?.scrollTo({
                  top: stepScrollerRef.current?.scrollTop + 60,
                  behavior: 'smooth',
                });
              }}
            >
              <Flex
                alignItems="center"
                borderRadius="full"
                justifyContent="center"
                boxSize={10}
              >
                <MdIcon fontSize="md" name="ArrowDownward" color="icon.muted" />
              </Flex>
            </Box>
          </Flex>
        )}
        <Box
          {...(embed
            ? {
                py: { base: 1, md: 1 },
                px: { base: 0, md: 1 },
                bg: 'rgba(255, 255, 255, 0.75)',
                backdropFilter: 'blur(10px)',
                borderRadius: 'full',
                transition: 'transform 0.5s',
                transform: {
                  base: 'translateY(-100px)',
                  lg: 'translateX(-100px)',
                },
                overflow: 'hidden',
                pointerEvents: 'auto',
                ...(isTouchDevice
                  ? {
                      ...(touchBackgroundBlurred
                        ? {
                            transform: {
                              base: 'translateY(0px)',
                              lg: 'translateX(0px)',
                            },
                          }
                        : {}),
                    }
                  : {
                      _groupHover: {
                        transform: {
                          base: 'translateY(0px)',
                          lg: 'translateX(0px)',
                        },
                      },
                    }),
              }
            : {
                ref: stepScrollerRef,
                onScroll: (e) => {
                  const target = e.target as HTMLElement;
                  const notTop = target.scrollTop > 0;
                  const bottom =
                    target.scrollHeight - target.scrollTop <=
                    target.clientHeight + 2;
                  if (notTop && !showTopScrollButton) {
                    setShowTopScrollButton(true);
                  } else if (!notTop && showTopScrollButton) {
                    setShowTopScrollButton(false);
                  }
                  if (bottom && showBottomScrollButton) {
                    setShowBottomScrollButton(false);
                  } else if (!bottom && !showBottomScrollButton) {
                    setShowBottomScrollButton(true);
                  }
                },
                position: { base: 'relative', lg: 'absolute' },
                top: 0,
                bottom: 14,
                left: 0,
                right: 0,
                overflow: 'scroll',
                sx: {
                  '::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                },
              })}
        >
          <Box
            {...(embed
              ? {
                  mx: { base: -2, md: 0 },
                }
              : {})}
          >
            <SessionStepNavigation
              currentStepIdx={currentStepIdx || 0}
              maxUnlockedIdx={maxUnlockedIdx}
              onOpenNotes={(idx) => setNotesStepIdx(idx)}
              setCurrentStepIdx={navigateToStep}
              steps={steps}
              previewModeEnabled={previewModeEnabled}
              isEditable={isEditable}
              handleAddStep={handleAddStep}
              showRequirements={showRequirements}
              openRequirements={() => setDisplayRequirements(true)}
              disableNavigation={isAssessment}
              size="sm"
            />
          </Box>
        </Box>
      </Box>
      <Box flex={1} ml={{ base: 0, lg: embed ? 0 : 4 }}>
        <SessionStepper
          onSetOrienation={onSetOrienation}
          sessionSubsteps={updatedSubsteps}
          currentSessionStepId={currentStep.id.toString()}
          currentSessionStepType={currentStep.stepType}
          title={stepTitle}
          isEditable={isEditable}
          onSaveClip={onSaveClip}
          handleVideoUpload={handleVideoUpload}
          handleAddClip={handleAddClip}
          onTouchBlurBackground={(blur: boolean) =>
            setTouchBackgroundBlurred(blur)
          }
          embed={embed}
        />
      </Box>

      {/* ------ REQUIREMENTS MODAL ------ */}
      {requirementItems && (
        <Modal
          isOpen={displayRequirement}
          onClose={() => setDisplayRequirements(false)}
        >
          <ModalOverlay />
          <ModalContent borderRadius="md">
            <ModalHeader>Checklist</ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH="75vh" overflow="scroll" pb={4}>
              <CheckList items={requirementItems} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* ------ NOTES MODAL ------ */}
      <Modal
        isOpen={Boolean(notesToDisplay)}
        onClose={() => setNotesStepIdx(null)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="75vh" overflow="scroll" pb={4}>
            {notesToDisplay && <RenderHtml html={notesToDisplay} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default SessionPlayer;
