import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Topic } from 'discourse-js';
import pickBy from 'lodash/pickBy';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';

import { discourseUrl, PLATFORM } from 'constants/env';

import { GlobalState } from 'types';
import { discourseActions } from 'redux/actions/common';
import { toggleUserMessaging } from 'redux/actions/common/ui';

import { simplifyName, emojifyText, courseUtils } from 'utils';
import MessageBus from 'utils/messageBus';

import { ChatWidget } from 'components/ChatWidget';

const PrivateMessaging = () => {
  /** TODO:
   * Refactor this component and ChatWidget so that the ChatWidget doesn't
   * receive a list of conversations but compiles it instead
   *
   * i.e, pass data from the store into ChatWidget and let it do the
   * relevant calculations (to prevent un-necessary re-renders of all the
   * conversations every time a new message is sent/received)
   */

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const discourseUserId = useSelector(
    (state: GlobalState) => state.user?.discourseUser?.user?.id
  );

  const currentUserId = useSelector(
    (state: GlobalState) => state.user?.userDetails?.id
  );

  // Extract all unread notifications linked to a specific topic
  const unreadNotifications = useSelector((state: GlobalState) =>
    Object.values(state.discourse.notifications).filter(
      (notification) => !notification.read && notification.topicId != null
    )
  );

  const members = useSelector((state: GlobalState) => state.discourse.members);

  const privateTopics = useSelector((state: GlobalState) =>
    pickBy(
      state.discourse.topics,
      (t) => t.id && t.archetype === 'private_message'
    )
  );

  const cohorts = useSelector((state: GlobalState) => {
    const allCohorts = {
      ...state.cms.cohort,
      ...state.learner.courses.cohorts,
    };
    return Object.keys(allCohorts).map((k) => allCohorts[k]);
  });

  /**
   * Build a mapping of userId -> topicId
   * this allows us to retrieve the relevant topics
   * for each private message with a given user
   * --> { userId1 : topicId1, useId2: topicId2, etc...}
   */
  const userPrivateMessageTopicIds = useSelector((state: GlobalState) =>
    Object.values(state.discourse.messages)
      .filter((m) => m.archetype === 'private_message')
      .filter((m) => !m.participants?.find((p) => p.userId < 0))
      .reduce((acc: { [key: number]: number }, m) => {
        const firstParticipant =
          m.participants?.length && m.participants[0].userId;

        return firstParticipant
          ? { ...acc, [firstParticipant]: m.id }
          : { ...acc };
      }, {})
  );

  /**
   * Turn userPrivateMessageTopicIds into a mapping of userId -> topic
   */
  const userPrivateMessageTopics = Object.keys(
    userPrivateMessageTopicIds
  ).reduce((acc: { [key: number]: Partial<Topic> }, userId) => {
    let topicId = userPrivateMessageTopicIds[parseInt(userId)];
    let topic = privateTopics[topicId];

    return topic ? { ...acc, [userId]: topic } : { ...acc };
  }, {});

  const conversations = useSelector((state: GlobalState) =>
    Object.keys(state.ui.messaging)
      .sort((a, b) => {
        /** Sort conversations by index */
        const convA = state.ui.messaging[parseInt(a)];
        const convB = state.ui.messaging[parseInt(b)];

        return !convA.idx || !convB.idx ? -1 : convA.idx - convB.idx;
      })
      .filter((k) => {
        /**
         * Conversations should all have either a userId or discourseGroupTopicId
         * (either of these will be required in the
         * conversation details for the ChatWidget)
         */
        const conversation = state.ui.messaging[parseInt(k)];
        return (
          'userId' in conversation || 'discourseGroupTopicId' in conversation
        );
      })
      .map((k) => {
        let topicId: number = 0;
        let topic: Partial<Topic> | undefined = {};
        const conversation = state.ui.messaging[parseInt(k)];
        let userId: number | undefined;
        let discourseGroupTopicId: number | undefined;

        if ('userId' in conversation) {
          userId = conversation.userId;
          topic = userPrivateMessageTopics[userId];
          topicId = userPrivateMessageTopicIds[userId];
        }

        if ('discourseGroupTopicId' in conversation) {
          discourseGroupTopicId = conversation.discourseGroupTopicId;
          topicId = discourseGroupTopicId;
          topic = state.discourse.topics[topicId];
        }

        const posts = topic?.postStream?.posts || [];

        const stream = topic?.postStream?.stream || [];

        // On the initial render we fetch the topic posts in reverse order
        // and only fetch the first 20 posts - the stream contains the id
        // of all the posts that remain to be loaded as the user interacts
        // with the ChatWidget (i.e, when scrolling up the list of messages)
        const remainingPosts = stream
          .filter((id) => !posts.find((p) => p.id === id))
          .sort((a, b) => b - a);

        const loadMorePosts =
          remainingPosts.length && Boolean(topicId)
            ? () =>
                dispatch(
                  discourseActions.getTopicPosts(
                    topicId,
                    remainingPosts.slice(0, 20),
                    {
                      reverse: true,
                    }
                  )
                )
            : null;

        // Transform the topic posts into a list of messages
        // to be consumed by the chat widget
        const messages = posts.map((p) => ({
          date: p.createdAt,
          fromUser: p.userId === discourseUserId,
          messageId: p.id,
          status: 'sent' as const,
          user: {
            id: p.userId,
            username: p.username,
            name: p.name,
            avatar: discourseUrl.concat(
              p.avatarTemplate.replace('{size}', '48')
            ),
          },
          text: (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(emojifyText(p.cooked), {
                  allowedTags: ['p', 'img', 'a'],
                  allowedClasses: { img: ['emoji'] },
                  transformTags: {
                    a: (tagName, attr) => ({
                      tagName,
                      attribs: { target: '_blank', href: attr.href },
                    }),
                  },
                }),
              }}
            />
          ),
        }));

        const unreadMessage = unreadNotifications.find(
          (n) => n.topicId === topic?.id
        );

        const { title, isExpanded } = conversation;

        const member = userId && members[userId.toString()];

        const memberIsMentor = Boolean(
          member && cohorts.find((c) => c.mentor?.username === member?.username)
        );

        const cohort =
          discourseGroupTopicId &&
          cohorts.find(
            (c) => c.discourseGroupChatTopicId === discourseGroupTopicId
          );
        const currentUserIsMentor = Boolean(
          discourseUserId &&
            cohort &&
            cohort.mentors?.find((m) => m.id === currentUserId)
        );
        const cohortName = cohort
          ? `${moment(cohort.startDate).format("Do MMM [']YY")}${
              cohort.label ? ` - ${cohort.label}` : ''
            }`
          : undefined;
        const cohortType = cohort ? courseUtils.getCohortType(cohort) : null;
        const cohortIcon = cohortType ? cohortType.icon : undefined;

        // Anonymise regular users
        const formattedTitle =
          userId && !memberIsMentor ? simplifyName(title) : title;

        const conversationUser = member
          ? {
              username: member.username,
              avatar: member.avatarTemplate,
              memberIsMentor,
            }
          : undefined;

        return {
          details: {
            title: formattedTitle,
            subtitle: currentUserIsMentor ? cohortName : undefined,
            subtitleIcon: currentUserIsMentor ? cohortIcon : undefined,
            userId,
            discourseGroupTopicId,
            conversationUser,
          },
          messages,
          expanded: isExpanded,
          topicId,
          unreadMessage: Boolean(unreadMessage),
          loadMorePosts,
        };
      })
  );

  const topicIds = Object.keys(privateTopics).map(
    (k) => privateTopics[parseInt(k)].id
  );

  useEffect(() => {
    /**
     * Iinitial loading of the data - fetch all private messages
     * (only happens once when the component first renders)
     */

    setIsLoading(true);

    const fetchData = async () => {
      await dispatch(discourseActions.getPrivateMessages());
    };

    fetchData().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    /**
     * Listen for new messages by subscribing to relevant channel
     */
    if (!topicIds.length) return;

    const loadNewPosts = async (
      data?: { type: string; id: number },
      topicId?: number
    ) => {
      if (!data || data.type !== 'created' || !topicId) return;

      await dispatch(
        discourseActions.getTopicPosts(topicId, [data.id], {
          reverse: true,
          // TS definitions need updating in discourse-js
          // @ts-ignore
          include_suggested: false,
        })
      );
    };

    topicIds.forEach((topicId) => {
      if (!topicId) return;

      const loadData = (data?: { type: string; id: number }) => {
        loadNewPosts(data, topicId);
      };
      MessageBus.subscribeToTopicChanges(topicId, loadData);
    });

    return () => {
      // Cleanup function - stop listening to all the channels we subscribed to
      if (!topicIds.length) return;

      topicIds.forEach((topicId) => {
        if (!topicId) return;
        MessageBus.unsubscribeFromTopicChanges(topicId);
      });
    };
  }, [topicIds.length]);

  return (
    <>
      {(Boolean(conversations.length) || PLATFORM === 'steppit') && (
        <ChatWidget
          conversations={conversations}
          onLoadTopic={(topicId) =>
            dispatch(discourseActions.getTopic(topicId, false, true))
          }
          isLoading={isLoading}
          onClose={(userId, discourseGroupTopicId) => {
            const data = { title: '', username: '' };

            if (discourseGroupTopicId) {
              dispatch(
                toggleUserMessaging({ ...data, discourseGroupTopicId }, false)
              );
              return;
            }

            if (userId) {
              dispatch(toggleUserMessaging({ ...data, userId }, false));
            }
          }}
          onToggleExpanded={(topicId) => {
            const topicNotifications = unreadNotifications.filter(
              (n) => n.topicId === topicId
            );

            if (!topicNotifications.length) return;

            setTimeout(() => {
              // Adding a timeout to keep the expand/collapse animation smooth
              // (without it the animation happens while the component re-renders
              // which makes it slightly jittery)

              topicNotifications.forEach((n) =>
                dispatch(discourseActions.markNotificationRead(n.id))
              );
            }, 500);
          }}
          onSubmit={async (message, title, topicId, conversationUserName) => {
            if (!topicId && !conversationUserName) return null;

            const action =
              conversationUserName && !topicId
                ? discourseActions.createPost({
                    raw: message,
                    title,
                    archetype: 'private_message',
                    targetUsernames: conversationUserName,
                  })
                : topicId
                ? discourseActions.sendPrivateMessage(topicId, message)
                : null;

            if (!action) return null;

            const post = await dispatch(action);

            if (!topicId) {
              await dispatch(discourseActions.getPrivateMessages());
            }

            return post || null;
          }}
        />
      )}
    </>
  );
};

export default PrivateMessaging;
