import React, { useState, useEffect } from 'react';

import {
  Flex,
  Text,
  Box,
  Stack,
  Divider,
  Button,
  Card,
  Collapse,
  Spinner,
  Link,
  chakra,
} from '@workshop/ui';
import { useHistory, useLocation } from 'react-router';

import { useCurrentTeamProfile, useCurrentTeam } from 'redux/selectors';

import { analytics, hooks } from 'utils';
import { IVideoClip } from 'types/cms';

import { PRO_ORGS } from 'constants/organisation';

import {
  LabelInput,
  ButtonGrid,
  ProCta,
  AssistantModal,
  InformationCard,
} from 'components/Common';
import { IAddItem } from 'components/ListItem';

import { VideoClipItem } from 'screens/cms/SessionEdit';

interface SessionManualPlanProps {
  courseId: string;
  sessionId: string;
  introClipId: string;
  outroClipId: string;
  isBuilding: boolean;
  setIsBuilding: (isBuilding: boolean) => void;
  handleAddStep: (
    data: IAddItem,
    addClip?: boolean,
    noToast?: boolean
  ) => Promise<number | null>;
  handleAddClip: (
    stepId?: string,
    data?: Partial<VideoClipItem>,
    noToast?: boolean
  ) => Promise<void>;
  handleSaveClip: (clipId: string, data: Partial<IVideoClip>) => Promise<any>;
  planSlug?: string;
}

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const getOrdinal = (n: number) => {
  let ord = 'th';
  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st';
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd';
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd';
  }
  return ord;
};

const BitInput = ({
  stepId,
  stepNumber,
  id,
  bitNumber,
  onRemove,
  onChange,
  summary,
}: {
  stepId: number;
  stepNumber: number;
  id: number;
  bitNumber: number;
  onRemove: (id: number) => void;
  onChange: (summary: string) => void;
  summary: string;
}) => {
  return (
    <Flex w="100%" px={4}>
      <LabelInput
        id={`plan-bit-${stepId}-${id}`}
        flex={1}
        label=""
        onChange={(e) => onChange(e.target.value)}
        labelStyleProps={{ fontSize: 'xs' }}
        labelPosition="top"
        placeholder={
          bitNumber === 1
            ? 'What comes first?'
            : bitNumber === 2
            ? 'And then?'
            : bitNumber === 3
            ? 'What next?'
            : bitNumber === 4
            ? 'After that?'
            : bitNumber === 5
            ? 'Anything else?'
            : 'Finally?'
        }
        value={summary}
      />
      {(bitNumber > 1 || stepNumber > 1) && (
        <Button
          icon="Remove"
          colorScheme="red"
          variant="outline"
          size="sm"
          w={8}
          alignSelf="center"
          mb="defaultMargin"
          ml={3}
          onClick={() => onRemove(id)}
        />
      )}
    </Flex>
  );
};

const StepInput = ({
  id,
  stepNumber,
  onRemove,
  onChange,
  onBitsUpdate,
  title,
  plannerStep,
  sessionStyle,
}: {
  id: number;
  stepNumber: number;
  onRemove: (id: number) => void;
  onChange: (title: string) => void;
  onBitsUpdate: (
    bits: { id: number; summary: string; script?: string }[]
  ) => void;
  title: string;
  plannerStep: number;
  sessionStyle: string;
}) => {
  const initialBit = {
    id: 0,
    summary: '',
  };
  const [bits, setBits] = useState<(typeof initialBit)[]>(
    stepNumber === 1 ? [initialBit] : []
  );
  const bitsStr = bits.map((b) => b.summary).toString();
  const updateBits = () => {
    onBitsUpdate(bits);
  };
  useEffect(() => updateBits(), [bitsStr]);

  return (
    <>
      <Flex w="100%">
        <LabelInput
          id={`plan-step-${id}`}
          flex={1}
          label={`Step ${stepNumber}`}
          onChange={(e) => onChange(e.target.value)}
          labelStyleProps={{ fontSize: 'xs' }}
          labelPosition="top"
          placeholder={`Which ${
            sessionStyle === 'passive'
              ? 'subtopic will you cover'
              : 'part of the activity comes'
          } ${stepNumber}${getOrdinal(stepNumber)}?`}
          value={title}
          fontWeight="semibold"
        />
        {(stepNumber > 3 || (stepNumber > 1 && plannerStep > 1 && !title)) && (
          <Button
            icon="Remove"
            colorScheme="red"
            variant="outline"
            size="sm"
            w={8}
            alignSelf="center"
            mb="defaultMargin"
            mt={7}
            ml={3}
            onClick={() => onRemove(id)}
          />
        )}
      </Flex>
      <Collapse in={plannerStep === 2}>
        <>
          <Flex pb={0.5} />
          {bits.length === 0 ? (
            <Flex w="100%" justifyContent="flex-start" px={4}>
              <Button
                variant="outline"
                icon="HdrStrong"
                borderStyle="dashed"
                size="sm"
                onClick={() => setBits([initialBit])}
              >
                Add Bits
              </Button>
            </Flex>
          ) : (
            bits
              .sort((a, b) => a.id - b.id)
              .map((bit, bitIdx) => (
                <Flex key={`plan-bit-${id}-${bit.id}`}>
                  <BitInput
                    id={bit.id}
                    stepNumber={stepNumber}
                    stepId={id}
                    summary={bit.summary}
                    bitNumber={bitIdx + 1}
                    onRemove={() =>
                      setBits((prevBits) => [
                        ...prevBits.filter((b) => b.id !== bit.id),
                      ])
                    }
                    onChange={(summary) => {
                      setBits((prevBits) => {
                        const newBits = [
                          ...prevBits.filter((b) => b.id !== bit.id),
                          { ...bit, summary },
                        ];
                        const maxId = Math.max(...prevBits.map((s) => s.id));
                        if (bit.id === maxId && prevBits.length < 6) {
                          if (summary) {
                            return [
                              ...newBits,
                              { ...initialBit, id: maxId + 1 },
                            ];
                          }
                        }
                        return newBits;
                      });
                    }}
                  />
                </Flex>
              ))
          )}
          {bits.length === 6 && (
            <Text fontSize="xs" color="text.muted">
              Max. 6 bits per step
            </Text>
          )}
          <Divider my={3} />
        </>
      </Collapse>
    </>
  );
};

const SessionManualPlan: React.FC<SessionManualPlanProps> = ({
  courseId,
  sessionId,
  introClipId,
  outroClipId,
  isBuilding,
  setIsBuilding,
  handleAddStep,
  handleAddClip,
  handleSaveClip,
  planSlug,
}) => {
  const initialStep = {
    id: 0,
    title: '',
    bits: [] as {
      id: number;
      summary: string;
    }[],
  };
  const [steps, setSteps] = useState([
    initialStep,
    { ...initialStep, id: 1 },
    { ...initialStep, id: 2 },
  ]);
  const [plannerStep, setPlannerStep] = useState(0);
  const [sessionStyle, setSessionStyle] = useState('');
  const [buildComplete, setBuildComplete] = useState(false);
  const [isManualPlanning, setIsManualPlanning] = useState(false);
  const [assistantOpen, setAssistantOpen] = useState(false);
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const currentRoute = hooks.useCurrentRoute();
  const isApp = currentRoute?.isApp;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    analytics.track('Session Planner Started', {
      type: 'manual',
    });
  }, []);

  useEffect(() => {
    if (planSlug) {
      setAssistantOpen(true);
    }
  }, [planSlug]);

  const missingFirstBit =
    steps.sort((a, b) => a.id - b.id)[0].bits.filter((b) => !!b.summary)
      .length === 0;

  const nextDisabled =
    plannerStep === 0
      ? !sessionStyle
      : plannerStep === 1
      ? !steps.sort((a, b) => a.id - b.id)[0].title
      : steps.filter((s) => !s.title).length > 0 || missingFirstBit;

  const buildSession = async (
    dataSteps?: {
      id: number;
      title: string;
      bits: {
        id: number;
        summary?: string;
        script?: string;
        mediaType?: 'video' | 'audio' | 'image' | 'text' | 'embed';
      }[];
    }[],
    intro?: {
      id: string;
      summary?: string;
      script?: string;
      mediaType?: 'video' | 'audio' | 'image' | 'text' | 'embed';
    },
    outro?: {
      id: string;
      summary?: string;
      script?: string;
      mediaType?: 'video' | 'audio' | 'image' | 'text' | 'embed';
    }
  ) => {
    setIsBuilding(true);
    const stepsToBuild = dataSteps || steps;
    const buildSteps = stepsToBuild
      .filter((s) => !!s.title)
      .sort((a, b) => a.id - b.id);
    for (const s of buildSteps) {
      const stepId = await handleAddStep(
        { inputText: s.title },
        s.bits.length === 0,
        true
      );
      const buildBits = s.bits
        .filter((b) => !!b.script || !!b.summary)
        .sort((a, b) => a.id - b.id);
      for (const b of buildBits) {
        await handleAddClip(
          `${stepId}`,
          {
            script: b.script || '',
            summary: b.summary || '',
            // @ts-ignore
            clipType: 'video',
            // // @ts-ignore
            // ...('mediaType' in b && b.mediaType
            //   ? // @ts-ignore
            //     { clipType: b.mediaType }
            //   : {}),
          },
          true
        );
      }
    }
    if (intro) {
      await handleSaveClip(intro.id, {
        script: intro.script || '',
        summary: intro.summary || '',
        clipType: 'video',
        // ...(intro.mediaType ? { clipType: intro.mediaType } : {}),
      });
    }
    if (outro) {
      await handleSaveClip(outro.id, {
        script: outro.script || '',
        summary: outro.summary || '',
        clipType: 'video',
        // ...(outro.mediaType ? { clipType: outro.mediaType } : {}),
      });
    }
    analytics.track('Session Planner Completed', {
      type: 'manual',
    });
    setBuildComplete(true);
    await timeout(3000);
    if (isApp) {
      // Indicate to the app to redirect to session page
      let searchParams = new URLSearchParams();
      searchParams.set('appSession', sessionId);
      searchParams.set('appCourse', courseId);
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
      return;
    }
    setIsBuilding(false);
  };

  return (
    <>
      <Card width="100%" position="relative">
        {isManualPlanning && !isBuilding && (
          <Button
            position="absolute"
            top={3}
            left={3}
            icon="ArrowBack"
            size="sm"
            w={8}
            variant="ghost"
            onClick={() =>
              plannerStep === 0
                ? setIsManualPlanning(false)
                : setPlannerStep(plannerStep - 1)
            }
          />
        )}
        <Flex
          py={4}
          px={plannerStep >= 1 ? { base: 2, sm: 4 } : 0}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          maxW="600px"
          mx="auto"
          mb={3}
        >
          <Collapse in={isBuilding && !buildComplete}>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="100%"
              color="common.primary"
              mt={3}
              minHeight="100px"
            >
              <Spinner mb={4} />
              <Text>Building your session...</Text>
            </Flex>
          </Collapse>
          <Collapse in={isBuilding && buildComplete}>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="100%"
              mt={3}
              mb={7}
              minHeight="100px"
            >
              <Flex
                boxSize="image.lg"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                borderRadius="full"
              >
                <Text fontSize="6xl">🙌</Text>
              </Flex>
              <Text
                fontWeight="extrabold"
                fontSize={{ base: '3xl', md: '4xl' }}
                lineHeight="1.2"
              >
                Your session is ready!
              </Text>
            </Flex>
          </Collapse>
          <Collapse in={!isBuilding}>
            <Flex flexDirection="column" alignItems="center" textAlign="center">
              <Collapse in={plannerStep >= 1}>
                <Flex
                  boxSize="image.lg"
                  alignItems="center"
                  justifyContent="center"
                  zIndex={1}
                  borderRadius="full"
                >
                  <Text fontSize="6xl">
                    {plannerStep === 1
                      ? sessionStyle === 'passive'
                        ? '🗂️'
                        : '✍️'
                      : sessionStyle === 'passive'
                      ? '🎙️'
                      : '👣'}
                  </Text>
                </Flex>
              </Collapse>

              <Text
                fontWeight="extrabold"
                fontSize={{ base: '3xl', md: '4xl' }}
                lineHeight="1.2"
                mb={2}
                mt={plannerStep === 0 ? 6 : 0}
                transition="margin-top 0.3s"
              >
                {plannerStep === 0
                  ? "Let's plan your session"
                  : plannerStep === 1
                  ? sessionStyle === 'passive'
                    ? 'Break up your topic'
                    : 'Map out your activity'
                  : sessionStyle === 'passive'
                  ? 'Write your talking points'
                  : 'Break down your steps'}
              </Text>
              <Flex flexDirection="column" w="100%">
                <Box mb={6}>
                  <Text
                    color="text.muted"
                    fontSize="lg"
                    whiteSpace="break-spaces"
                  >
                    {plannerStep === 0
                      ? isManualPlanning
                        ? 'How do you want your class to follow this session?'
                        : 'How do you want to plan this session?'
                      : plannerStep === 1
                      ? sessionStyle === 'passive'
                        ? "We'll start by breaking up your topic into digestible steps. Think of each step like a 2-3 word subtopic within your session."
                        : "We'll start by breaking your activity down into a few broad steps that break your session into parts. Give each step a 2-3 word title."
                      : sessionStyle === 'passive'
                      ? 'Each subtopic can now be broken down into 1-2 sentence talking points, so that your class can digest the session bit by bit.'
                      : 'Each step can now be broken down into actionable, 1-2 sentence instructions, so that your class can actively follow along bit by bit.'}
                  </Text>
                  <Collapse in={plannerStep === 0 && !isManualPlanning}>
                    <ButtonGrid
                      m={4}
                      mt={8}
                      items={[
                        {
                          slug: 'manual',
                          name: 'Build from Scratch',
                          description: `Break your session down into steps and bits yourself.`,
                          icon: 'Create',
                          onClick: () => {
                            setTriggerOpenProPopup(undefined);
                            setIsManualPlanning(true);
                          },
                        },
                        {
                          slug: 'ai',
                          name: 'Generate with My Assistant',
                          description: isPro
                            ? `Work with your assistant to generate content for your session.`
                            : 'Upgrade your channel to generate content for your session.',
                          icon: 'AutoAwesome',
                          onClick: () => {
                            if (isPro) {
                              setAssistantOpen(true);
                            } else {
                              setTriggerOpenProPopup(!triggerOpenProPopup);
                            }
                          },
                          isActive: assistantOpen,
                          footerElement: isPro ? undefined : (
                            <Box mt={4}>
                              <ProCta
                                hideCta
                                triggerOpenPopup={triggerOpenProPopup}
                              />
                            </Box>
                          ),
                        },
                      ]}
                    />
                  </Collapse>
                  <Collapse in={plannerStep === 0 && isManualPlanning}>
                    <ButtonGrid
                      m={4}
                      mt={6}
                      items={[
                        {
                          slug: 'passive',
                          name: 'Just Watch',
                          description: `Take your class through a topic for them to digest bit by bit. Best for theoretical subjects.`,
                          emoji: '📺',
                          onClick: () => setSessionStyle('passive'),
                          isActive: sessionStyle === 'passive',
                        },
                        {
                          slug: 'active',
                          name: 'Learn by Doing',
                          description: `Guide your class through a hands-on activity one step at a time. Best for practical subjects.`,
                          emoji: '🤸',
                          onClick: () => setSessionStyle('active'),
                          isActive: sessionStyle === 'active',
                        },
                      ]}
                    />
                  </Collapse>
                  <Collapse in={plannerStep === 2}>
                    <>
                      <Flex
                        bg="background.primary"
                        p={4}
                        borderRadius="md"
                        flexDirection="column"
                        alignItems="center"
                        maxW="500px"
                        mx="auto"
                        mt={6}
                        mb={6}
                      >
                        <Text whiteSpace="break-spaces" color="text.primary">
                          <chakra.span fontWeight="bold">Bits</chakra.span> of
                          content are the building blocks of your session.
                          Later, you can add tiny video or audio clips to each
                          one.
                        </Text>
                      </Flex>

                      <Text
                        whiteSpace="break-spaces"
                        color="text.muted"
                        fontSize="lg"
                        pb={2}
                      >
                        {`Try ${
                          sessionStyle === 'passive'
                            ? 'writing a few talking points for'
                            : 'adding a few instructions to'
                        } your first step now. You can always make changes or add more later!`}
                      </Text>
                    </>
                  </Collapse>
                </Box>

                <Collapse in={plannerStep >= 1}>
                  <>
                    {steps
                      .sort((a, b) => a.id - b.id)
                      .map((step, stepIdx) => (
                        <Flex
                          key={`plan-step-${step.id}`}
                          flexDirection="column"
                          px={1}
                        >
                          <StepInput
                            id={step.id}
                            title={step.title}
                            stepNumber={stepIdx + 1}
                            onRemove={() =>
                              setSteps((prevSteps) => [
                                ...prevSteps.filter((s) => s.id !== step.id),
                              ])
                            }
                            onChange={(title) =>
                              setSteps((prevSteps) => [
                                ...prevSteps.filter((s) => s.id !== step.id),
                                { ...step, title },
                              ])
                            }
                            onBitsUpdate={(bits) =>
                              setSteps((prevSteps) => [
                                ...prevSteps.filter((s) => s.id !== step.id),
                                { ...step, bits },
                              ])
                            }
                            plannerStep={plannerStep}
                            sessionStyle={sessionStyle}
                          />
                        </Flex>
                      ))}

                    <Button
                      variant="outline"
                      size="sm"
                      icon="Queue"
                      borderStyle="dashed"
                      mt={4}
                      mb={10}
                      onClick={() => {
                        const maxId = Math.max(...steps.map((s) => s.id));
                        setSteps((prevSteps) => [
                          ...prevSteps,
                          { ...initialStep, id: maxId + 1 },
                        ]);
                      }}
                    >
                      Add a Step
                    </Button>
                  </>
                </Collapse>

                <Collapse in={isManualPlanning}>
                  <>
                    {plannerStep <= 1 ? (
                      <Box mb={6}>
                        <Button
                          alignSelf="center"
                          icon="ArrowForward"
                          iconPosition="right"
                          size="lg"
                          w="100%"
                          maxWidth="200px"
                          onClick={() => {
                            analytics.track('Session Planner Step Completed', {
                              type: 'manual',
                            });
                            setPlannerStep(plannerStep + 1);
                            if (plannerStep === 1) {
                              setSteps((prevSteps) => [
                                ...prevSteps.filter((s) => !!s.title),
                              ]);
                            }
                          }}
                          disabled={nextDisabled}
                          mb={2}
                        >
                          Next
                        </Button>
                        {nextDisabled && plannerStep === 1 && (
                          <Text fontSize="xs" color="text.muted">
                            Add at least 1 step to continue
                          </Text>
                        )}
                      </Box>
                    ) : (
                      <Box mb={6}>
                        <Button
                          alignSelf="center"
                          icon="CheckCircle"
                          size="lg"
                          w="100%"
                          maxWidth="200px"
                          mb={2}
                          onClick={() => buildSession()}
                          disabled={nextDisabled}
                        >
                          Build Session
                        </Button>
                        {nextDisabled && (
                          <Text fontSize="xs" color="text.muted">
                            {missingFirstBit
                              ? 'Add a bit to your first step to continue'
                              : 'Give every step a title to continue'}
                          </Text>
                        )}
                      </Box>
                    )}

                    <Stack
                      direction="row"
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {['', '', ''].map((s, idx) => (
                        <Flex
                          key={`planner-step-indicator-${idx}`}
                          height={2}
                          width={idx === plannerStep ? 8 : 2}
                          bg={
                            idx > plannerStep
                              ? 'background.tint2'
                              : 'common.progress'
                          }
                          borderRadius="full"
                          transition="all 0.3s"
                        />
                      ))}
                    </Stack>
                  </>
                </Collapse>
              </Flex>
            </Flex>
          </Collapse>
        </Flex>
      </Card>
      <Box
        mt={6}
        mx={{ base: 'defaultMargin', md: 0 }}
        cursor="pointer"
        _hover={{ opacity: 0.8, transform: 'scale(1.01)' }}
        transition="transform 0.3s"
        display={planSlug && isApp ? 'none' : 'block'}
      >
        <Link
          href="https://www.steppit.com/resources/how-to-plan-your-first-session-ai/"
          target="_blank"
          rel="noopener noreferrer"
          textDecoration="none!important"
        >
          <InformationCard
            id="session-plan-demo"
            information={{
              title: 'Watch our demo on planning sessions',
              description: "We'll take you through it step by step.",
              thumbnail:
                'https://cdn.steppit.com/media/public/cache/courses/o4hM78oW/upload/40f302126fded7f1c98452e181ae4c70.jpg',
              overlay: true,
            }}
            isDismissable={false}
            cta={
              <Button
                icon="ArrowForward"
                iconPosition="right"
                size="sm"
                variant="outline"
                mt={{ base: 3, md: 0 }}
                ml={{ base: 0, md: 3 }}
              >
                Go
              </Button>
            }
          />
        </Link>
      </Box>
      <AssistantModal
        isOpen={assistantOpen}
        onClose={() => setAssistantOpen(false)}
        toolSlug="sessions"
        context={{ courseId, sessionId }}
        planSlug={planSlug}
        onSave={async (data) => {
          if (data) {
            // @ts-ignore
            const dataSteps = data.steps.map((s, idx) => ({
              id: idx,
              title: s.stepTitle,
              // @ts-ignore
              bits: s.bits.map((b, bIdx) => ({
                id: bIdx,
                script: b.bitScript,
                summary: b.bitSummary,
                mediaType: 'video',
                // ...(data.mediaType ? { mediaType: data.mediaType } : {}),
              })),
            }));
            buildSession(
              dataSteps,
              {
                id: introClipId,
                script: data.intro?.sessionIntroScript || '',
                summary: data.intro?.sessionIntroSummary || '',
                mediaType: 'video',
                // ...(data.mediaType ? { mediaType: data.mediaType } : {}),
              },
              {
                id: outroClipId,
                script: data.outro?.sessionOutroScript || '',
                summary: data.outro?.sessionOutroSummary || '',
                mediaType: 'video',
                // ...(data.mediaType ? { mediaType: data.mediaType } : {}),
              }
            );
          }
        }}
      />
    </>
  );
};

export default SessionManualPlan;
