import React, { useState, useEffect } from 'react';
import { Message } from 'react-hook-form';

import { useCurrentTeam, useCurrentTeamProfile } from 'redux/selectors';

import { PRO_ORGS } from 'constants/organisation';
import { AssistantToolSlug } from 'constants/assistant';

import {
  Flex,
  FormLabel,
  Text,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
  FormLabelProps,
  Box,
  BoxProps,
  Button,
  Tooltip,
} from '@workshop/ui';

import { ProCta, AssistantModal } from 'components/Common';

export type AiButtonProps = {
  toolSlug: AssistantToolSlug;
  toolVariant?: string;
  label: string;
  onProClick?: () => void;
  tooltip: string;
  isLoading?: boolean;
  loadingText?: string;
  isOutline?: boolean;
  isDisabled?: boolean;
  alwaysShow?: boolean;
  position?: 'left' | 'right';
  context?: { courseId?: string };
  onSave: (editedResponse: { [key: string]: string }) => Promise<any>;
};

interface LabelWrapperProps {
  errorMessage?: Message;
  helpText?: string | React.ReactNode;
  helpTextPosition?: 'underline' | 'inline' | 'top';
  labelStyleProps?: FormLabelProps;
  inputId?: string;
  isInvalid?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  label?: string;
  labelPosition?: 'top' | 'inline';
  labelAlign?: 'center' | 'flex-start';
  labelButtonText?: string;
  labelButtonOnClick?: () => void;
  unit?: string;
  loadingStyle?: BoxProps;
  noMargin?: boolean;
  setIsFocused?: (isFocused: boolean) => void;
  isFocused?: boolean;
  aiButton?: AiButtonProps;
}

const LabelWrapper: React.FC<LabelWrapperProps> = ({
  children,
  errorMessage,
  helpText,
  helpTextPosition = 'underline',
  inputId,
  isInvalid,
  isLoading = false,
  isRequired,
  label,
  labelPosition = 'inline',
  labelStyleProps = {},
  labelAlign = 'center',
  labelButtonText,
  labelButtonOnClick,
  unit,
  loadingStyle,
  noMargin = false,
  setIsFocused,
  isFocused,
  aiButton,
}) => {
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);
  const [assistantOpen, setAssistantOpen] = useState<boolean | undefined>(
    undefined
  );
  const [hide, setHide] = useState<boolean>(false);
  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );
  useEffect(() => {
    return () => setTriggerOpenProPopup(undefined);
  }, []);

  useEffect(() => {
    if (assistantOpen === false) {
      // Force refresh the textbox when the assistant is closed
      setHide(true);
      setIsFocused && setIsFocused(false);
      setTimeout(() => setHide(false), 1);
    }
  }, [assistantOpen]);

  return (
    // minHeight = 2 x default margin
    <Skeleton
      isLoaded={!isLoading}
      minHeight={isLoading ? 6 : 'none'}
      mb={
        noMargin
          ? aiButton && (isFocused || aiButton?.alwaysShow)
            ? 3
            : 0
          : aiButton && (isFocused || aiButton?.alwaysShow)
          ? 5
          : 'defaultMargin'
      }
      transition="margin-bottom 0.15s"
      loadingStyle={loadingStyle}
      flex={1}
    >
      <Flex flexShrink={0} flexDirection="column">
        <FormControl isInvalid={isInvalid} isRequired={isRequired}>
          {label && labelPosition === 'top' && (
            <Flex
              flexDirection={{ base: 'column', sm: 'row' }}
              flex={1}
              minWidth={24}
              alignItems={{ base: 'flex-start', sm: 'center' }}
            >
              <FormLabel
                htmlFor={inputId}
                color="text.muted"
                fontSize="md"
                flex={1}
                {...labelStyleProps}
              >
                {label.trim() ? `${label}` : label}
              </FormLabel>
              {labelButtonText && labelButtonOnClick && (
                <Button
                  variant="outline"
                  size="xs"
                  onClick={labelButtonOnClick}
                  ml={{ base: 0, sm: 2 }}
                  mb={2}
                >
                  {labelButtonText}
                </Button>
              )}
            </Flex>
          )}
          <Flex flexDirection="row" alignItems={labelAlign}>
            {label && labelPosition === 'inline' && (
              <Flex flexDirection="column" flex={1} minWidth={28}>
                <FormLabel
                  htmlFor={inputId}
                  color="text.muted"
                  fontSize="md"
                  mb={0}
                  {...labelStyleProps}
                >
                  {label.trim() ? `${label}:` : label}
                </FormLabel>
              </Flex>
            )}
            <Flex flexDirection="row" flex={4}>
              <Flex flexDirection="row" alignItems="center" flex={1}>
                <Flex flexDirection="column" flex={2} position="relative">
                  {helpText && helpTextPosition === 'top' && (
                    <Flex mb={2}>
                      <FormHelperText
                        id={inputId}
                        fontSize="xs"
                        color="text.muted"
                        whiteSpace="pre-wrap"
                      >
                        {helpText}
                      </FormHelperText>
                    </Flex>
                  )}
                  {!hide && children}
                  {aiButton && (
                    <>
                      <Box
                        position="absolute"
                        bottom={-3}
                        transition="opacity 0.3s"
                        zIndex={1}
                        {...(aiButton.position === 'left'
                          ? { left: 4 }
                          : { right: 4 })}
                        {...(isFocused || aiButton.alwaysShow
                          ? {
                              opacity: 1,
                              pointerEvents: 'auto',
                            }
                          : {
                              opacity: 0,
                              pointerEvents: 'none',
                            })}
                      >
                        <Tooltip label={aiButton.tooltip} textAlign="center">
                          <Box>
                            <Button
                              size="xs"
                              zIndex={1}
                              icon="AutoAwesome"
                              colorScheme={isPro ? 'blue' : 'orange'}
                              isLoading={aiButton.isLoading}
                              isDisabled={aiButton.isDisabled}
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => {
                                if (isPro) {
                                  if (aiButton.onProClick) {
                                    aiButton.onProClick();
                                  } else {
                                    setAssistantOpen(true);
                                  }
                                } else {
                                  setTriggerOpenProPopup(!triggerOpenProPopup);
                                }
                              }}
                              {...(aiButton.isOutline && !aiButton.isLoading
                                ? {
                                    variant: 'outline',
                                    bg: 'background.default',
                                  }
                                : {})}
                            >
                              {aiButton.label}
                            </Button>
                            {aiButton.isLoading && (
                              <Text
                                position="absolute"
                                textAlign="center"
                                fontSize="xs"
                                width="100%"
                                mt={1}
                                color="text.muted"
                              >
                                {aiButton.loadingText || 'Loading...'}
                              </Text>
                            )}
                          </Box>
                        </Tooltip>
                      </Box>
                      <Box height={0}>
                        <ProCta
                          hideCta
                          hideProIcon
                          label=""
                          triggerOpenPopup={triggerOpenProPopup}
                        />
                      </Box>
                      {assistantOpen && (
                        <AssistantModal
                          isOpen={assistantOpen}
                          onClose={() => setAssistantOpen(false)}
                          toolSlug={aiButton.toolSlug}
                          toolVariant={aiButton.toolVariant}
                          context={aiButton.context}
                          // @ts-ignore
                          onSave={aiButton.onSave}
                        />
                      )}
                    </>
                  )}
                </Flex>
                {unit && (
                  <Flex flexDirection="column" flex={1}>
                    <Text
                      fontFamily="body"
                      fontSize="xs"
                      color="text.muted"
                      marginLeft={2}
                    >
                      {unit}
                    </Text>
                  </Flex>
                )}
              </Flex>
              {helpText && helpTextPosition === 'inline' && (
                <Flex flex={3} marginLeft={4} alignItems="center">
                  <FormHelperText
                    id={inputId}
                    fontSize="xs"
                    color="text.muted"
                    whiteSpace="pre-wrap"
                  >
                    {helpText}
                  </FormHelperText>
                </Flex>
              )}
            </Flex>
          </Flex>
          {helpText && helpTextPosition === 'underline' && (
            <Flex flexDirection="row">
              {label && labelPosition === 'inline' && (
                <Flex flexDirection="column" flex={1} minWidth={28} />
              )}
              <Flex
                flexDirection="column"
                flex={4}
                mt={aiButton && (isFocused || aiButton.alwaysShow) ? 2 : 0}
                transition="margin-top 0.15s"
              >
                <FormHelperText
                  id={inputId}
                  fontFamily="body"
                  fontSize="xs"
                  color="text.muted"
                  whiteSpace="pre-wrap"
                >
                  {helpText}
                </FormHelperText>
              </Flex>
            </Flex>
          )}
          <Flex flexDirection="row">
            {label && labelPosition === 'inline' && (
              <Flex flexDirection="column" flex={1} minWidth={28} />
            )}
            <Flex flexDirection="column" flex={4}>
              <FormErrorMessage
                color="text.error"
                fontSize="xs"
                marginY={2}
                fontFamily="body"
                display="block"
              >
                {errorMessage}
              </FormErrorMessage>
            </Flex>
          </Flex>
        </FormControl>
      </Flex>
    </Skeleton>
  );
};

export default LabelWrapper;
